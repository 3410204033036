<template>
    <div class="container">
        <div class="name-card">
            Naam: {{ this.$parent.username + (this.$parent.username.toUpperCase() === 'STANLEY' ? " (host)" : "" ) }}
        </div>

        <div v-if="this.gameState == 1" class="body">
            <button class="btn btn-primary" id="throwBanana" @click="throwBanana()">Gooi een banaan!</button>
        </div>

        <div v-else-if="this.gameState == 2" class="body">
            <p class="name-card">Heeft Patrick dit heerlijk gedaan?</p>
            <div class="button-block">
                <button class="btn btn-primary" id="vote-yes" @click="vote(true)">Ja</button>
                <button class="btn btn-primary" id="vote-no" @click="vote(false)">Nee</button>
            </div>
        </div>

        <div v-else-if="this.gameState == 3" class="body">
            <div class="item-container">
                <p class="name-card">Stel een {{ this.topic }} vraag aan Patrick.</p>
                
                <input type="text" id="question" placeholder="Vraag" class="question" />
                <button class="btn btn-primary" id="sendQuestion" @click="sendQuestion()">Verstuur vraag</button>
            </div>
        </div>

        <div v-else-if="this.gameState == 4 && this.jamiro == this.$parent.username" class="body">
            <!--Jamiro will have to solve a christmas cosine-->
            <div class="item-container">
                <p class="name-card">
                    Wat is de kerst cosinus van alfa (α)?<br><br>
                    <img src="../assets/cos.png" alt=""/>
                </p>

                <p class="name-card">a = 3 <br> b = 5 <br> c = 8</p>
                <input type="text" id="cosine-answer" placeholder="Antwoord" class="question" />
                <button class="btn btn-primary" id="sendAnswer" @click="sendCosineAnswer()">Verstuur antwoord</button>
            </div>
        </div>

        <div v-else-if="this.$parent.username.toUpperCase() === 'STANLEY'" class="body admin">
            <ul class="connectedUsers">
                <li v-for="item in this.$parent.connectedUsers" v-bind:key="item" class="name-card">
                    {{ (item == jamiro ? item + " (Jamiro)" : item) }}
                    <button class="btn-small" @click="setJamiro(item)">Jamiro</button>
                </li>
            </ul>

            <button class="btn btn-primary" id="startButton" @click="onStart()">Start</button>
        </div>

        <div v-else class="body">
            <img src="../assets/homer-simpson.gif" alt=""/>
        </div>
        
        <footer v-if="this.$parent.username.toUpperCase() === 'STANLEY'" class="footer">
            <button class="btn-warning" id="restart" @click="onRestart()">Restart</button>
        </footer>
    </div>
</template>

<script>
import socket from "../socket";

export default {
    name: "WaitingScreen",
    data() {
        return {
            gameState: 0,
            topics: ["topografische", "politieke", "muzikale", "ondeugende", "juridische"],
            topic: "",
            jamiro: ""
        }
    },
    methods: {
        onStart() {
            socket.emit("nextGameState");
        },
        onRestart() {
            socket.emit("restart");
        },
        throwBanana() {
            socket.emit("throwBanana");
            document.getElementById("throwBanana").disabled = true;
            document.getElementById("throwBanana").innerHTML = "30";
            var i = 29;
            var interval = setInterval(function() {
                document.getElementById("throwBanana").innerHTML = i;
                i--;
                if (i < 0) {
                    clearInterval(interval);
                    document.getElementById("throwBanana").disabled = false;
                    document.getElementById("throwBanana").innerHTML = "Gooi een banaan!";
                }
            }, 1000);
        },
        vote(vote) {
            socket.emit("vote", vote);
            this.gameState = 0;
        },
        sendQuestion() {
            socket.emit("question", {
                topic: this.topic,
                content: document.getElementById("question").value
            });

            this.gameState = 0;
        },
        setJamiro(jamiro) {
            socket.emit("setJamiro", jamiro);
        },
        sendCosineAnswer() {
            socket.emit("cosineAnswer", document.getElementById("cosine-answer").value == "0.625");
        }
    },
    created() {
        this.topic = this.topics[Math.floor(Math.random() * this.topics.length)];

        socket.on("gameState", (state) => {
            this.gameState = state;
        });

        socket.on("jamiro", (jamiro) => {
            this.jamiro = jamiro;
        });
    }
}
</script>

<style>
.name-card {
    background-color: #272727;
    padding: 2rem;
    color: white;
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: large;
}

.connectedUsers {
    list-style-type: none;
    padding: 2rem;
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5.4rem;
    padding-bottom: 10rem;
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    background-color: #1b1b1b;
}

.item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    bottom: -1rem;
}

.button-block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10rem;
    bottom: 0;
}

.footer {
    display: flex;
    position: fixed;
    bottom: 0rem;
    width: 100%;
    height: 5rem;
    justify-content: center;
    align-items: center;
    background-color: #272727;
}

.btn {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 0;
    border: none;
    background-color: #272727;
    color: white;
}

.btn-small {
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 2rem;
    border: none;
    background-color: #272727;
    color: white;
}

.btn-warning {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 0;
    border: none;
    background-color: #f32727;
    color: white;
}

.btn-warning:hover {
    background-color: #ff0000;
    color: white;
}

.btn:hover {
    background-color: #4b7145;
    color: white;
}

.btn-small:hover {
    background-color: #9d18a6;
    color: white;
}

.question {
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 2rem;
    margin-top: 2rem;
    border-radius: 0;
    border: none;
    background-color: #272727;
    color: white;
}

</style>