<template>
  <div id="app">
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"/>

    <select-username
      v-if="!usernameAlreadySelected"
      @input="onUsernameSelection"
    />
    <WaitingScreen v-else />
  </div>
</template>

<script>
import SelectUsername from "./components/SelectUsername";
import WaitingScreen from "./components/WaitingScreen";
import socket from "./socket";

export default {
  name: "App",
  components: {
    SelectUsername,
    WaitingScreen
  },
  data() {
    return {
      usernameAlreadySelected: false,
      username: localStorage.getItem("username"),
      connectedUsers: []
    };
  },
  methods: {
    onUsernameSelection(username) {
      this.usernameAlreadySelected = true;
      socket.auth = { username };
      socket.connect();

      localStorage.setItem("username", username);
      this.username = username;
    },
  },
  created() {
    const sessionID = localStorage.getItem("sessionID");

    if (sessionID) {
      this.usernameAlreadySelected = true;
      socket.auth = { sessionID };
      socket.connect();
    }

    socket.on("session", ({ sessionID, userID }) => {
      // attach the session ID to the next reconnection attempts
      socket.auth = { sessionID };
      // store it in the localStorage
      localStorage.setItem("sessionID", sessionID);
      // save the ID of the user
      socket.userID = userID;
    });

    socket.on("users", (users) => {
      this.connectedUsers = [];
      users.forEach(user => {
        if(user.connected) {
          this.connectedUsers.push(user.username);
        }
      });
    });

    socket.on("user connected", ({username}) => {
      this.connectedUsers.push(username);
    });

    socket.on("connect_error", (err) => {
      if (err.message === "invalid username") {
        this.usernameAlreadySelected = false;
      }
    });
  },
  destroyed() {
    socket.off("connect_error");
  },
};
</script>

<style>
body {
  margin: 0;
}

@font-face {
  font-family: Lato;
  src: url("/fonts/Lato-Regular.ttf");
}

#app {
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
}
</style>
